import * as React from "react";
import ReactDOM from "react-dom/client";
import "./src/styles/global.css";
import { GlobalLayout } from "./src/components/shared/global-layout";

const onClientEntry = () => {
  //console.log("onClientEntry");
  // Ensure scroll restoration on initial load
  window.addEventListener("popstate", () => {
    const currentScrollPosition = scrollPositions[window.location.key];
    if (currentScrollPosition !== undefined) {
      window.setTimeout(() => {
        window.scrollTo(0, currentScrollPosition);
      }, 0);
    }
  });
};

// Ensure to restore scroll position when the user navigates back.

let scrollPositions = {};

const onPreRouteUpdate = ({ location }) => {
  // To achieve the scroll position restoration, we need to store the scroll position
  // before the router updates the location.
  scrollPositions[location.key] = window.scrollY;
};

const onRouteUpdate = ({ location }) => {
  if (location.href.indexOf("#") !== -1) {
    return;
  }
  // restore scroll position if any previous position data is stored
  const currentScrollPosition = scrollPositions[location.key];
  if (currentScrollPosition === undefined) {
    return;
  }
  window.setTimeout(() => {
    window.scrollTo(0, currentScrollPosition);
  }, 0);
};

//const onRouteUpdate = ({ location }) => {
//  if (location.hash) {
//    const id = location.hash.replace('#', '');
//    const element = document.getElementById(id);
//    if (element) {
//      window.setTimeout(() => {
//        element.scrollIntoView();
//      }, 0);
//    }
//  } else {
//    window.scrollTo(0, 0);
//  }
//};

// click to release hash
const enableClickToReleaseHash = () => {
  if (!window) return;
  window.addEventListener("click", (e) => {
    if (!window.location.hash) return;
    window.history.replaceState(
      null,
      window.document.title,
      window.location.pathname
    );
  });
};

const onInitialClientRender = () => {
  enableClickToReleaseHash();
};

const wrapPageElement = ({ element, props }) => {
  enableClickToReleaseHash();
  return <GlobalLayout {...props}>{element}</GlobalLayout>;
};

// not sure why but this is necessary to avoid error
// https://stackoverflow.com/questions/77651939/getting-uncaught-error-minified-react-error-418-on-my-gatsby-production-build
const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export {
  onInitialClientRender,
  wrapPageElement,
  onClientEntry,
  onRouteUpdate,
  onPreRouteUpdate,
  replaceHydrateFunction,
};
